<template>
  <div class="expert-recommend">
    <img class="er-title" :src="require('../../../assets/image/index/index_e.png')" />
    <div class="er-label">
      <div
        @click="setExpertLabel(index)"
        :class="{'er-label-select':selectExpertId===index}"
        class="er-label-text" v-for="(item,index) in data&&data.labels" :key="index">
        {{ item.name }}
      </div>
    </div>
    <div class="er-list">
      <div class="er-body"
           @click="goExpertDetail(item.id)"
           v-for="item in data &&
          data.labels.length > 0 && data.labels[selectExpertId].objs"
           :key="item.id">
        <div class="er-main">
          <image-loader :width="130" :height="130" :src="item.pic" :mode="'cover'" />
          <div class="erm-des">
            <div class="erm-des-fontA ellipse-1" style="font-weight:bold">
              {{ item.name }}
            </div>
            <div class="erm-des-fontA ellipse-1">
              {{ item.pa }}
            </div>
<!--            <div class="erm-des-fontB">-->
<!--              计算机科学与应用-->
<!--            </div>-->
            <div class="erm-des-more">专家详情</div>
          </div>
        </div>
      </div>

    </div>
<!--    <div class="er-more"-->
<!--         @click="goExpertMore"-->
<!--    >更多专家>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "expertRecommend",
  props: {
    data: Object
  },
  watch: {
    data: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectExpertId = 0;

        }
      }
    }
  },
  data() {
    return {
      selectExpertId: -1
    };
  },
  methods: {
    setExpertLabel(id) {
      this.selectExpertId = id;
    },
    goExpertMore() {
      this.$router.push({
        path: "qixiao",
      });
    },
    goExpertDetail(id) {
      this.$router.push({
        path: "experts",
        query: {
          shopId: id
        }
      });
    }
  }
};
</script>

<style>
.expert-recommend {
  background-image: url("../../../assets/image/index/index_bga.png");
  display: flex;
  flex-direction: column;
  align-items: center;
}

.er-title {
  width: 633px;
  height: 45px;
  margin-top: 50px;
}

.er-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1250px;
}

.er-body {
  width: 410px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.er-main {
  width: 380px;
  height: 170px;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
}

.er-main:hover {
  width: 390px;
  height: 180px;
  box-shadow: 0px 5px 20px 0px rgba(255, 255, 255, 0.5);
}

.erm-img {
  width: 130px;
  height: 130px;
  border-radius: 4px;
}

.er-label {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #E5E8ED;
  height: 35px;
  margin-top: 20px;
  justify-content: center;
}

.er-label-text {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
  width: 200px;
  display: flex;
  justify-content: center;
  height: 35px;
  cursor: pointer;
}

.er-label-select {
  color: rgba(24, 144, 255, 1);
  border-bottom: 1px solid rgba(24, 144, 255, 1);
}

.erm-des {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.erm-des-fontA {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 10px;
}

.erm-des-fontB {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}

.erm-des-more {
  width: 76px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #1890FF;
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.er-more {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  margin-top: 45px;
  margin-bottom: 50px;
  cursor: pointer;
}
</style>
